
import Helmet from "react-helmet"
import { withPrefix, Link } from "gatsby"
import About from "../components/homedefault/about";
import Banner from "../components/homedefault/banner";
import BlogPost from "../components/blogPost";
import Brand from "../components/homedefault/brand";
import Contact from "../elements/contact/contact";
import Layout from "../components/layout";
import Project from "../components/homedefault/project";
import React from "react";
import SEO from "../components/seo";
import Service from "../components/homedefault/service";
import TaxiBoat from "../components/homedefault/taxiboat";
import Testimonial from "../components/homedefault/testimonial";

const Index = () => (
  <Layout>
    <Helmet>
        <script src={withPrefix('hotjar.js')} type="text/javascript" />
    </Helmet>
    <SEO title="Sole Nautica - Rent A Boat, Cres, Croatia" />
    <Banner />
    <About />
    
    <div className="portfolio-id" id="portfolio">
      <Project />
      <TaxiBoat />
    </div>
    <BlogPost />
    <Service />
    <Contact />
  </Layout>
)
export default Index;