import {graphql, useStaticQuery} from 'gatsby';

import Projectcard from "./projectcard";
import React from 'react';

const ProjectOne = () => {
    const ProjectData = useStaticQuery(graphql`
        query ProjectDataQuery {
            allProjectJson(limit: 9) {
                edges {
                  node {
                    id
                    title
                    category
                    bl
                    bw
                    ce
                    pax
                    kg
                    fuel
                    water
                    engine
                    speed
                    pricefrom
                    season1
                    season2
                    season3
                    featured_image {
                        childImageSharp {
                            fluid(maxWidth: 374, maxHeight: 374, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                  }
                }
            }
        }
    `);

    const projectsData = ProjectData.allProjectJson.edges;
    return (
        <div className="row row--45 mt_dec--30">
            {projectsData.map( data => (
                <Projectcard key={data.node.id}
                    column="col-lg-4 col-md-6 col-12"
                    portfolioStyle="portfolio-style-1"
                    key={data.node.id} 
                    id={data.node.id}
                    image={data.node.featured_image.childImageSharp}
                    title={data.node.title}
                    category={data.node.category}
                    bl={data.node.bl}
                    bw={data.node.bw}
                    ce={data.node.ce}
                    pax={data.node.pax}
                    kg={data.node.kg}
                    fuel={data.node.fuel}
                    water={data.node.water}
                    engine={data.node.engine}
                    speed={data.node.speed}
                    pricefrom={data.node.pricefrom}
                    season1={data.node.season1}
                    season2={data.node.season2}
                    season3={data.node.season3}
                />
            ))}
        </div>
    )
}

export default ProjectOne;