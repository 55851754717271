import { Controller, Scene } from 'react-scrollmagic';
import {graphql, useStaticQuery} from 'gatsby';

import Img from "gatsby-image";
import React from 'react'
import plavalaguna from '../images/banner/plavalaguna.jpg';

const TaxiBoat = ( ) => {
    const taxiBoatQueryData = useStaticQuery(graphql`
        query TaxiBoatDefaultQuery {
            homedefaultJson (id: {eq: "taxiboat"}) {
                title
                description
                subtitle
                description2
                downloadButton
                linkBUtton
                
            },
            file(relativePath: {eq: "images/banner/lubenice.jpeg"}) {
                childImageSharp {
                  fixed (quality: 100, width: 518, height: 480) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
        }
    `);

    const title = taxiBoatQueryData.homedefaultJson.title;
    const Subtitle = taxiBoatQueryData.homedefaultJson.subtitle;
    const description = taxiBoatQueryData.homedefaultJson.description;
    const description2 = taxiBoatQueryData.homedefaultJson.description2;
    const downloadButton = taxiBoatQueryData.homedefaultJson.downloadButton;
    const linkBUtton = taxiBoatQueryData.homedefaultJson.linkBUtton;
    const PortfolioImages = taxiBoatQueryData.file.childImageSharp.fixed;


    return (
        <div className="rb-about-area about-style rn-section-gap bg-color-white" id="about">
            <div className="container">
                <div className="row row--45 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <div className="trigger" id="trigger12" />
                            {/* <Controller>
                                <Scene classToggle="animated" triggerElement="#trigger12" triggerHook="onCenter"> */}
                                    <div className="rn_surface story-image">
                                        <Img className="about-images" fixed={PortfolioImages} alt={title + "Valun, Lubenice, Cres"}/>
                                    </div>
                                {/* </Scene>
                            </Controller> */}
                        </div>
                    </div>
                    <div className="col-lg-7" style={{marginBottom: "40px"}}>
                        <div className="inner">
                            <div className="content">
                                <div className="section-title">
                                    <div className="title-wrap">
                                        <h3 className="title wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">{title}<span className="bg">CRES</span></h3>
                                        {title && <h4 className="subtitle wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: Subtitle }}></h4>}
                                    </div>
                                    
                                    {description && <p className="description wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: description }}></p>}
                                    {description && <p className="description wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: description2 }}></p>}
                                </div>
                                {/* <div className="button-group mt--30">
                                    {downloadButton && <a className="rn-button wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" href="#downloadbutton"><span>{downloadButton}</span></a>}
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="thumbnail">
                            <div className="trigger" id="trigger22" />
                            {/* <Controller>
                                <Scene classToggle="animated" triggerElement="#trigger22" triggerHook="onCenter"> */}
                                    <div className="rn_surface story-image">
                                        <img className="about-images" src={plavalaguna}  style={{borderRadius:"5px"}} alt="Plava Laguna, Cres"/>
                                    </div>
                                {/* </Scene>
                            </Controller> */}
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default TaxiBoat
