import { FiActivity, FiMonitor, FiNavigation } from "react-icons/fi";

import { GiAnchor } from "react-icons/gi"
import React from 'react';

const Service = () => {
    return (
        <div className="rn-service-area rn-section-gapBottom" style={{paddingBottom: "50px !important", marginTop: "50px !important"}}>
            {/* Start Service Area  */}
            <div className="rn-service-area">
                <div className="container">
                    <div className="row">

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <GiAnchor />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">HIRE A SKIPPER?</h4>
                                        <p>Do you have the necessary skills to skipper your own boat or would you hire one while you're on your holiday? Contact us.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <GiAnchor />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">DISCOUNTS FOR GROUPS</h4>
                                        <p>If you are planning to rent more than one boat, you are sure to receive a substancial discount for every next boat. So far there has never been a client that would leave us without "thank you so much"</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <GiAnchor />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">LET'S GO HAVE SOME FUN</h4>
                                        <p>Book one of our boat rental deals with unlimited mileage. With our special offers you can easily find cheap boat rental in last minute.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                    </div>
                </div>
            </div>
            {/* End Service Area  */}

        </div>
    )
}

export default Service;