import { Controller, Scene } from 'react-scrollmagic';
import {FiMessageSquare, FiPhoneCall} from "react-icons/fi"
import {graphql, useStaticQuery} from 'gatsby';

import {HiOutlineMailOpen} from "react-icons/hi"
import Img from "gatsby-image";
import React from 'react'
import girl from "../images/banner/girl.jpg"

const About = ( ) => {
    const aboutQueryData = useStaticQuery(graphql`
        query AboutDefaultQuery {
            homedefaultJson (id: {eq: "about"}) {
                title
                description
                subtitle
                description2
                downloadButton
                linkBUtton
                
            },
            file(relativePath: {eq: "images/banner/girl.jpg"}) {
                childImageSharp {
                  fixed (quality: 100, width: 518, height: 480) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
        }
    `);

    const title = aboutQueryData.homedefaultJson.title;
    const Subtitle = aboutQueryData.homedefaultJson.subtitle;
    const description = aboutQueryData.homedefaultJson.description;
    const description2 = aboutQueryData.homedefaultJson.description2;
    const downloadButton = aboutQueryData.homedefaultJson.downloadButton;
    const linkBUtton = aboutQueryData.homedefaultJson.linkBUtton;
    const PortfolioImages = aboutQueryData.file.childImageSharp.fixed;


    return (
        <div className="rb-about-area about-style rn-section-gap bg-color-white" id="about">
            <div className="container">
                <div className="row row--45 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <div className="trigger" id="trigger2" />
                            {/* <Controller>
                                <Scene classToggle="animated" triggerElement="#trigger2" triggerHook="onCenter"> */}
                                    <div className="rn_surface story-image">
                                        <Img className="about-images" fixed={PortfolioImages} alt="About Us - Sole Nautica - Rent A Boat in Cres, Croatia"/>
                                    </div>
                               {/*  </Scene>
                            </Controller> */}
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="inner">
                            <div className="content">
                                <div className="section-title">
                                    <div className="title-wrap">
                                        <h3 className="title wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">{title}<span className="bg">CRES</span></h3>
                                        {title && <h4 className="subtitle wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: Subtitle }}></h4>}
                                    </div>
                                    
                                    {description && <p className="description wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: description }}></p>}
                                    {description && <p className="description wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" dangerouslySetInnerHTML={{ __html: description2 }}></p>}
                                </div>
                               {/*  <div className="button-group mt--30">
                                    {downloadButton && <a className="rn-button wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms" href="#downloadbutton"><span>{downloadButton}</span></a>}
                                </div> */}
                              <div className="row">  
                            <div className="col-lg-6 col-md-6 col-12 mt--10">
                                <div style={{textAlign: "center", background: "#3aaae1", padding: "5px", border: "1px solid gray", borderRadius: "8px"}}>
                                    <span className="category"><a href="tel:+385915805907" style={{color: "white"}}> <FiPhoneCall size={28}/> Call Us</a></span>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12 mt--10">
                            <div style={{textAlign: "center", background: "#FFFFFF", padding: "5px", border: "1px solid gray", borderRadius: "8px"}}>
                                    <span className="category"><a href="mailto:krcina28@yahoo.com" style={{color: "black"}}> <HiOutlineMailOpen size={28}/> Email</a></span>
                                </div>
                            </div>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
